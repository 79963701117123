export default {
  methods: {
    getMathFunction(exp = 1) {
      return [
        [
          String.raw`\[linear\]`,
          (a, b, x) => (a + Math.round((b - a) * x)).toString(16).padStart(2, '0'),
        ],
        [
          exp > 1 ? String.raw`\[f(x)=\sqrt[${exp}]{x}\]` : String.raw`\[x (linear)\]`,
          (a, b, x) => (a + Math.round((b - a) * x ** (1 / exp))).toString(16).padStart(2, '0'),
        ],
        [
          exp > 1 ? String.raw`\[f(x)=x^{${exp}}\]` : String.raw`\[x (linear)\]`,
          (a, b, x) => (a + Math.round((b - a) * x ** exp)).toString(16).padStart(2, '0'),
        ],
        [
          exp > 1 ? String.raw`\[f(x)=\sqrt[${exp}]{sin\Bigl(x\frac{\pi}{2}\Bigr)}\]` : String.raw`\[f(x)=sin\Bigl(x\frac{\pi}{2}\Bigr)\]`,
          (a, b, x) => (a + Math.round((b - a) * Math.sin((x * Math.PI) / 2) ** (1 / exp))).toString(16).padStart(2, '0'),
        ],
        [
          String.raw`\[f(x)=sin${exp > 1 ? `^{${exp}}` : ''}\Bigl(x\frac{\pi}{2}\Bigr)\]`,
          (a, b, x) => (a + Math.round((b - a) * Math.sin((x * Math.PI) / 2) ** exp)).toString(16).padStart(2, '0'),
        ],
      ]
    },
    getRgbFromHex(hex = '#000000') {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5), 16)

      return [r, g, b]
    },
    calcColors(func, fromColor, toColor, points = 10, exp = 1) {
      const fromColorRgb = this.getRgbFromHex(fromColor)
      const toColorRgb = this.getRgbFromHex(toColor)

      let result = []
      for (let i = 0; i < points; i += 1) {
        const x = i / (points - 1)

        const r = func(fromColorRgb[0], toColorRgb[0], x, exp)
        const g = func(fromColorRgb[1], toColorRgb[1], x, exp)
        const b = func(fromColorRgb[2], toColorRgb[2], x, exp)

        result = [...result, [`#${r}${g}${b}`, `#${r}0000`, `#00${g}00`, `#0000${b}`]]
      }

      return result
    },
  },
}
